<script setup lang="ts">
import { computed, ref } from 'vue'
import { format } from 'date-fns'
import { useFocus } from '@vueuse/core'
import { parse, format as vFormat } from '../utils/v-table'
import { toMoney, Money, toNumber } from '../utils/money'

const canSet = (value, type) => {
  if (type === 'currency') {
    const stringNumber = value.toString().replace(/[^0-9.-]+/g, '')
    if (stringNumber === '.') return false
    const decimalNumbers = stringNumber.split('.')[1]
    if (stringNumber.split('.').length > 1 && decimalNumbers.length < 2) {
      if (decimalNumbers === '') return false
      if (decimalNumbers[decimalNumbers.length - 1] === '0') return false
    }
  }

  return true
}

const props = withDefaults(
  defineProps<{
    modelValue?: string | number | Date | Money
    description?: string
    disabled?: boolean
    error?: string
    id?: string
    inline?: boolean
    inputClass?: string
    label?: string
    name?: string
    onChange?: any
    onInput?: any
    placeholder?: string
    property?: string
    required?: boolean
    type?: string
    v$?: any
    useActionsSlot?: boolean
  }>(),
  {
    disabled: false,
    inline: false,
    placeholder: '',
    useActionsSlot: false,
    type: 'text',
    v$: {},
    onChange: () => {},
    onInput: () => {},
  },
)

const emit = defineEmits(['update:modelValue'])
const inputRef = ref(null)
const { focused: isFocused } = useFocus(inputRef)

const value = computed({
  get: () => {
    if (props.type === 'date_without_timezone' && props.modelValue) {
      // remove date's timezone

      const date_in_timezone = new Date(props.modelValue)
      const date = new Date(date_in_timezone.getTime() + date_in_timezone.getTimezoneOffset() * 60 * 1000)
      return format(date, 'yyyy-MM-dd')
    }
    if (isFocused.value) {
      if (props.type === 'percent' && props.modelValue) {
        return props.modelValue.toString().replace(/[^0-9.-]+/g, '')
      }
      if (props.type === 'currency') {
        const input_number = props.modelValue ? toNumber(props.modelValue as Money) : null
        if (!input_number && input_number !== 0) return null
        return parse(input_number, 'currency')
      }
    } else {
      if (props.type === 'percent' && props.modelValue) {
        return `${props.modelValue.toString().replace(/[^0-9.-]+/g, '')}%`
      }
      if (props.type === 'currency') {
        const currency_object = props.modelValue
          ? { value: toNumber(props.modelValue as Money), currency: props.modelValue?.currency || 'USD' }
          : null
        return vFormat(currency_object, 'currency-object')
      }
      if (props.type === 'number' && props.modelValue) {
        return vFormat(props.modelValue, 'number')
      }
    }
    return props.modelValue
  },
  set: (value) => {
    if (!canSet(value, props.type)) return
    let valueToEmit = value
    switch (props.type) {
      case 'currency':
        if (!value && value !== 0) {
          valueToEmit = null
        } else {
          valueToEmit = toMoney(
            parse(value, 'currency') || 0,
            props.modelValue?.currency || 'USD',
            props.modelValue?.common_amount,
            props.modelValue?.common_currency,
          )
        }
        break
    }
    emit('update:modelValue', valueToEmit)
  },
})

const error = computed(() => props.error || props.v$[props.property || props.name]?.$errors[0]?.$message)

const inputType = computed(() => {
  if (props.type === 'date_without_timezone') return 'date'
  if (props.type === 'percent') return 'text'
  if (props.type === 'currency') return 'text'
  if (props.type === 'number') return 'text'
  return props.type
})

const onInput = (e) => {
  if (!canSet(e.target.value, props.type)) return
  if (!props.onInput) return
  props.onInput()
}
</script>

<template>
  <fieldset>
    <label :for="id" class="block text-sm font-medium text-gray-700">
      <span>{{ label }}</span>
      <span v-if="required">*</span>
    </label>
    <div :class="{ 'mt-1': !inline && label, relative: useActionsSlot }">
      <input
        v-model="value"
        :class="[
          'block w-full',
          error
            ? 'border-red-300 focus:border-red-300 focus:ring-red-200'
            : 'border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50',
          inline ? 'border-none' : 'mt-1 rounded-md border',
          disabled ? 'bg-gray-50 text-gray-500' : '',
          inputClass,
        ]"
        :disabled="disabled"
        :id="id"
        :name="name || property"
        :placeholder="placeholder"
        :type="inputType"
        @change="onChange"
        @input="onInput"
        ref="inputRef"
      />
      <div class="absolute inset-y-0 left-0 pl-1.5" v-if="useActionsSlot">
        <slot name="actions"></slot>
      </div>
    </div>
    <template v-if="error && !inline">
      <p class="mt-1 text-sm text-red-500">{{ error }}</p>
    </template>
    <template v-if="description">
      <p class="mt-1 text-sm text-gray-500" :id="`${id}-description`">{{ description }}</p>
    </template>
  </fieldset>
</template>
