import { initialMoney, sum, minus, times, toNumber } from '@/modules/shared/utils/money'
import { computed } from 'vue'

type Transfer = {
  id: number | null
  capital: Money
  management_fee: Money
  other_fee: Money
  investor_set_commitment_id: number | null
  investor_set_transaction_id: number | null
  commitment: {
    id: number | null
    capital: Money
    investor: {
      _cid: string
      id: number | null
      name: string | null
    }
    management_fee_percentage: number | null
    carried_interest_percentage: number | null
    commitment_remaining: Money
    ownership_percentage: number | null
  }
}
type TransformedTransfer = {
  id: number | null
  is_active: boolean
  name: string
  capital: Money
  management_fees: Money
  other_fees: Money
  total_called: Money
  commitment_remaining: Money
  pre_commitment_remaining: Money
  ownership_percentage: number | null
  fund_total_capital_committed: Money
  investor_set_commitment_id: number | null
}
type Macros = {
  total_capital: Money
  management_fees: number
  other_fees: Money
}

const calculateTotalCapital = (transfer: TransformedTransfer, macros: Macros, config) => {
  const fund_total_capital_committed = transfer.fund_total_capital_committed
  const ownership_percentage = transfer.ownership_percentage
  let total_capital = macros.total_capital || initialMoney
  if (config.selectedCapitalType === '%') {
    total_capital = times(fund_total_capital_committed, (macros.total_capital || 0) / 100)
  }
  return total_capital
}
const getTimePeriodPercent = (time_period: string): number => {
  switch (time_period) {
    case '1':
      return 1
      break
    case '2':
      return 0.5
      break
    case '3':
      return 0.25
      break
    case '4':
      return 1 / 12
      break
    default:
      return 1
      break
  }
}
const calculateMacroManagementFees = (transfer: TransformedTransfer, macros: Macros, config) => {
  let management_fees = initialMoney
  const ownership_percentage = transfer.ownership_percentage || 0
  const total_capital = calculateTotalCapital(transfer, macros, config)

  switch (config.includeCapital) {
    case '1':
      if (config.allocateManagementFees === '1') {
        if (config.managementFeeBasedOn === 'commitment') {
          const commitment = transfer.commitment || initialMoney
          management_fees = times(commitment, transfer.management_fee_percentage)
        } else {
          const management_fee_percent = transfer.management_fee_percentage * ownership_percentage
          management_fees = times(total_capital, management_fee_percent)
        }
      } else if (config.allocateManagementFees === '2') {
        if (config.selectedManagementFeesType === '$') {
          const management_fees_amount = macros.management_fees || initialMoney
          management_fees = times(management_fees_amount, ownership_percentage)
        } else {
          if (config.managementFeeBasedOn === 'commitment') {
            const management_fee_percent = (macros.management_fees || 0) / 100
            const commitment = transfer.commitment || initialMoney
            management_fees = times(commitment, management_fee_percent)
          } else {
            const management_fee_percent = ((macros.management_fees || 0) / 100) * ownership_percentage
            management_fees = times(total_capital, management_fee_percent)
          }
        }
      }
      break
    case '3':
      const commitment = transfer.commitment || initialMoney

      if (config.allocateManagementFees === '1') {
        management_fees = times(commitment, transfer.management_fee_percentage)
      } else if (config.allocateManagementFees === '2') {
        if (config.selectedManagementFeesType === '$') {
          management_fees = macros.management_fees || initialMoney
        } else {
          management_fees = times(commitment, (macros.management_fees || 0) / 100)
        }
      }
      break
  }

  return times(management_fees, getTimePeriodPercent(config.selectedTimePeriod))
}
const calculateMacroCapital = (transfer: TransformedTransfer, macros: Macros, config) => {
  if (config.includeCapital === '3') return initialMoney

  const ownership_percentage = transfer.ownership_percentage
  const total_capital = calculateTotalCapital(transfer, macros, config)
  const capital_before_fees = times(total_capital, ownership_percentage)

  return minus(capital_before_fees, transfer.management_fees)
}
const calculateMacroOtherFees = (transfer: TransformedTransfer, macros: Macros, config) => {
  let other_fees = initialMoney
  if (config.includeCapital === '2') return other_fees
  const ownership_percentage = transfer.ownership_percentage

  return times(macros.other_fees || initialMoney, ownership_percentage)
}
const calculateTotalCalled = (transfer: TransformedTransfer): Money => {
  return sum([
    transfer.capital || initialMoney,
    transfer.management_fees || initialMoney,
    transfer.other_fees || initialMoney,
  ])
}
const calculateCommitmentRemaining = (transfer: TransformedTransfer): Money => {
  const commitment_remaining = minus(
    transfer.pre_commitment_remaining || initialMoney,
    transfer.total_called || initialMoney,
  )
  return commitment_remaining
}

export const useCapitalCall = (configComputed) => {
  const config = computed(() => configComputed.value)

  const transformTransfer = (transfer: Transfer, capital_call): TransformedTransfer => {
    const t = {
      id: transfer.id,
      is_active: true,
      name: transfer.commitment.investor.name,
      capital: transfer.capital,
      management_fees: transfer.management_fee,
      other_fees: transfer.other_fee,
      total_called: initialMoney,
      commitment: transfer.commitment.capital,
      commitment_remaining: initialMoney,
      pre_commitment_remaining: transfer.commitment.commitment_remaining,
      management_fee_percentage: transfer.commitment.management_fee_percentage || 0,
      ownership_percentage: transfer.commitment.ownership_percentage,
      fund_total_capital_committed: capital_call.fund_total_capital_committed,
      investor_set_commitment_id: transfer.investor_set_commitment_id,
      units: transfer.commitment.units,
    }

    t.total_called = calculateTotalCalled(t)
    t.commitment_remaining = calculateCommitmentRemaining(t)

    return t
  }

  const resetTransfer = (transfer: TransformedTransfer) => {
    transfer.capital = initialMoney
    transfer.management_fees = initialMoney
    transfer.other_fees = initialMoney
    transfer.total_called = calculateTotalCalled(transfer)
    transfer.commitment_remaining = calculateCommitmentRemaining(transfer)
    return transfer
  }

  const updateTransfer = (transfer: TransformedTransfer) => {
    transfer.total_called = calculateTotalCalled(transfer)
    transfer.commitment_remaining = calculateCommitmentRemaining(transfer)
    return transfer
  }

  const calculateAggregate = (transfers: TransformedTransfer[]): TransformedTransfer => {
    const aggregates = {
      capital: initialMoney,
      management_fees: initialMoney,
      other_fees: initialMoney,
      total_called: initialMoney,
      commitment_remaining: initialMoney,
    }
    transfers.forEach((transfer) => {
      if (transfer.is_active) {
        aggregates.capital = sum([aggregates.capital, transfer.capital || initialMoney])
        aggregates.management_fees = sum([aggregates.management_fees, transfer.management_fees || initialMoney])
        aggregates.other_fees = sum([aggregates.other_fees, transfer.other_fees || initialMoney])
        aggregates.total_called = sum([aggregates.total_called, transfer.total_called])
      }
      aggregates.commitment_remaining = sum([aggregates.commitment_remaining, transfer.commitment_remaining])
    })

    return aggregates
  }

  const updateMacroTransfer = (transfer: TransformedTransfer, macros: Macros) => {
    // we are calculating management fees first because capital is dependent on it
    transfer.management_fees = calculateMacroManagementFees(transfer, macros, config.value)
    transfer.capital = calculateMacroCapital(transfer, macros, config.value)
    transfer.other_fees = calculateMacroOtherFees(transfer, macros, config.value)
    transfer.total_called = calculateTotalCalled(transfer)
    transfer.commitment_remaining = calculateCommitmentRemaining(transfer)
    return transfer
  }

  return { resetTransfer, updateTransfer, calculateAggregate, updateMacroTransfer, transformTransfer }
}
