import { Money, toNumber, initialMoney, difference, times, minus, sum } from '@/modules/shared/utils/money'
import { is } from 'date-fns/locale'
import { computed } from 'vue'

type Transfer = {
  id: number
  capital: Money
  commitment: {
    id: number
    capital: Money
    carried_interest_percentage: number
    investor: {
      name: string
    }
    hurdle: Money
    management_fee_percentage: number
    ownership_without_fees_by_called_amount: number
    preferred_return_percentage: number
  }
  carried_interest_distributed: Money
  other_fee_distributed: Money
  preferred_return: Money
  return_of_capital: Money
  return_of_capital_limit: Money
}
type TransformedTransfer = {
  id: number
  investor_set_commitment_id: number
  is_active: boolean
  name: string
  units: number

  // normal distribution
  capital: Money
  preferred_return: Money
  profit: Money

  // interest distribution
  principal: Money
  interest: Money

  // shared
  total_capital: Money
  carried_interest_distributed: Money
  other_fee_distributed: Money
  net_distributed: Money
  hurdle_remaining: Money

  // hidden fields
  ownership_percentage: number
  commitment: Money
  commitment_invested: Money
  carried_interest_percentage: number
  original_carried_interest_percentage: number
  preferred_return_percentage: number
  pre_hurdle: Money
  return_of_capital_limit: Money
}
type Macros = {
  total_capital: Money
  carried_interest_distributed: number
  interest: Money
  other_fee_distributed: Money
}

////////////////////////////////////////
//// Calculations
////////////////////////////////////////

// Calculations for normal distribution
const calculateCapital = (transfer: TransformedTransfer) => {
  const total_capital = transfer.total_capital || initialMoney
  const return_of_capital_limit = transfer.return_of_capital_limit || initialMoney

  return toNumber(total_capital) >= toNumber(return_of_capital_limit) ? return_of_capital_limit : total_capital
}
const calculatePreferredReturn = (transfer: TransformedTransfer) => {
  let preferred_return = initialMoney

  const preferred_return_limit = transfer.preferred_return_limit || initialMoney
  if (toNumber(preferred_return_limit) <= 0) return preferred_return

  const total_capital = transfer.total_capital || initialMoney
  const capital = transfer.capital || initialMoney
  const pre_hurdle = transfer.pre_hurdle || initialMoney
  const capital_remaining = minus(total_capital, capital)
  let hurdle_remaining = minus(pre_hurdle, capital)

  // the commitment hurdle without the preferred_return has been met
  if (toNumber(hurdle_remaining) <= toNumber(preferred_return_limit) && toNumber(capital_remaining) > 0) {
    preferred_return =
      toNumber(capital_remaining) >= preferred_return_limit ? preferred_return_limit : capital_remaining
  }

  if (toNumber(preferred_return) >= toNumber(preferred_return_limit)) return preferred_return_limit
  return preferred_return
}
const calculateProfit = (transfer: TransformedTransfer) => {
  let amount = null
  const total_capital = transfer.total_capital || initialMoney
  const preferred_return = transfer.preferred_return || initialMoney
  const pre_hurdle = transfer.pre_hurdle || initialMoney
  let capital = transfer.capital || initialMoney
  capital = sum([capital, preferred_return])

  if (toNumber(total_capital) < toNumber(capital)) {
    amount = minus(capital, pre_hurdle)
  } else {
    amount = minus(total_capital, capital)
  }
  return toNumber(amount) > 0 ? amount : initialMoney
}
const calculateCarriedInterest = (transfer: TransformedTransfer, type: string) => {
  const carried_interest_percentage = transfer.carried_interest_percentage || 0
  let profit = transfer.profit || initialMoney
  if (type === 'interest') {
    profit = transfer.interest || initialMoney
  }

  return times(profit, carried_interest_percentage)
}

// Calculations for interest distribution
const calculatePrincipal = (transfer: TransformedTransfer) => {
  const total_capital = transfer.total_capital || initialMoney
  const interest = transfer.interest || initialMoney
  const principal = difference([total_capital, interest])
  const return_of_capital_limit = transfer.return_of_capital_limit || initialMoney

  return toNumber(principal) >= toNumber(return_of_capital_limit) ? return_of_capital_limit : principal
}

// Shared calculations
const calculateTotalDistributed = (transfer: TransformedTransfer, type: string) => {
  const other_fee_distributed = transfer.other_fee_distributed || initialMoney
  const carried_interest_distributed = transfer.carried_interest_distributed || initialMoney

  // for normal type
  let capital = transfer.capital || initialMoney
  let profit = transfer.profit || initialMoney
  let preferred_return = transfer.preferred_return || initialMoney

  if (type === 'interest') {
    capital = transfer.principal || initialMoney
    profit = transfer.interest || initialMoney
    preferred_return = initialMoney
  }

  // total capital is being calculated because capital and profit can be manually changed
  // the attributes of different types of distribution will not affect each other because it was set to initialMoney in transformTransfer
  const total_capital = sum([capital, preferred_return, profit])
  const value = difference([total_capital, carried_interest_distributed, other_fee_distributed])
  return toNumber(value) > 0 ? value : initialMoney
}
const calculateHurdleRemaining = (transfer: TransformedTransfer, type: string) => {
  const pre_hurdle = transfer.pre_hurdle || initialMoney
  let capital = transfer.capital || initialMoney
  let preferred_return = transfer.preferred_return || initialMoney

  if (type === 'interest') {
    capital = transfer.principal || initialMoney
    preferred_return = initialMoney
  }

  capital = sum([capital, preferred_return])
  const hurdle_remaining = minus(pre_hurdle, capital)
  if (toNumber(hurdle_remaining) <= 0) return initialMoney
  return hurdle_remaining
}
const calculateTransferValues = (
  transfer: TransformedTransfer,
  type: string,
  config,
  macros?: Macros,
  input_key?: string,
) => {
  if (!macros) {
    // this is for the transfers fields
    // we use switch to prevent the calculations of the specific column to affect its previous columns
    switch (input_key) {
      case 'total_capital':
        transfer.capital = calculateCapital(transfer)
        transfer.preferred_return = calculatePreferredReturn(transfer)
        transfer.profit = calculateProfit(transfer)
        transfer.principal = calculatePrincipal(transfer)
        break
    }

    if (!['carried_interest_distributed', 'other_fee_distributed'].includes(input_key))
      transfer.carried_interest_distributed = calculateCarriedInterest(transfer, type)
  } else {
    // this is for the auto calculations
    transfer.capital = calculateCapital(transfer)
    transfer.preferred_return = calculatePreferredReturn(transfer)
    transfer.profit = calculateProfit(transfer)
    transfer.principal = calculatePrincipal(transfer)
    transfer.carried_interest_distributed = calculateCarriedInterest(transfer, type)
  }

  transfer.net_distributed = calculateTotalDistributed(transfer, type)
  transfer.hurdle_remaining = calculateHurdleRemaining(transfer, type)
  return transfer
}

export const getDisbursementType = (
  type: 'Equity' | 'Convertible Note' | 'Loan' | 'Revenue Share' | 'Option' | 'Warrant' | 'Safe Note',
) => {
  if (['Loan', 'Safe Note', 'Convertible Note'].includes(type)) return 'interest'
  // if (['Equity', 'Revenue Share', 'Option', 'Warrant'].includes(type)) return 'portfolio'
  return 'normal'
}
export const useEuropeanDisbursement = (
  type?: 'Equity' | 'Convertible Note' | 'Loan' | 'Revenue Share' | 'Option' | 'Warrant' | 'Safe Note',
  configComputed,
) => {
  const config = computed(() => configComputed.value)
  const disbursement_type = getDisbursementType(type)

  const transformTransfer = (transfer: Transfer) => {
    let t = {
      id: transfer.id,
      investor_set_commitment_id: transfer.commitment.id,
      is_active: true,
      name: transfer.commitment.investor.name,
      units: transfer.commitment.units,

      // normal distribution
      capital: transfer.return_of_capital,
      preferred_return: transfer.preferred_return,
      profit: transfer.profit,

      // interest distribution
      principal: transfer.return_of_capital,
      interest: transfer.interest,

      // shared
      carried_interest_distributed: transfer.carried_interest_distributed,
      total_capital: transfer.capital,
      other_fee_distributed: transfer.other_fee_distributed,
      net_distributed: initialMoney,
      hurdle_remaining: initialMoney,

      // fields needed for computation
      return_of_capital_limit: transfer.return_of_capital_limit,
      preferred_return_limit: transfer.preferred_return_limit,
      pre_hurdle: transfer.commitment.hurdle,
      ownership_percentage: transfer.commitment.ownership_without_fees_by_called_amount,
      commitment: transfer.commitment.capital,
      commitment_preferred_return: transfer.commitment.preferred_return,
      carried_interest_percentage: transfer.commitment.carried_interest_percentage || 0,
      original_carried_interest_percentage: transfer.commitment.carried_interest_percentage || 0,
      preferred_return_percentage: transfer.commitment.preferred_return_percentage,
    }

    t.hurdle_remaining = calculateHurdleRemaining(t, disbursement_type)
    t.net_distributed = calculateTotalDistributed(t, disbursement_type)

    return t
  }
  // prettier-ignore
  const updateTransfer = (transfer: TransformedTransfer, key?: string) => calculateTransferValues(transfer, disbursement_type, config.value, null, key)
  const macrosUpdateTransfer = (transfer: TransformedTransfer, macros: Macros, key: string) => {
    if (key !== 'carried_interest_distributed') {
      const amount = macros[key] || initialMoney
      const output = times(amount, transfer.ownership_percentage)
      transfer[key] = toNumber(output) > 0 ? output : initialMoney
    } else {
      let percent = (macros.carried_interest_distributed || 0) / 100
      if (!macros.carried_interest_distributed && macros.carried_interest_distributed !== 0)
        percent = transfer.original_carried_interest_percentage
      transfer.carried_interest_percentage = percent
    }
    transfer = calculateTransferValues(transfer, disbursement_type, config.value, macros)
    return transfer
  }
  const calculateAggregate = (transfers: TransformedTransfer[]) => {
    const normal_distribution_keys = ['capital', 'profit', 'preferred_return']
    const interest_distribution_keys = ['principal', 'interest']
    const shared_keys = [
      'total_capital',
      'other_fee_distributed',
      'net_distributed',
      'hurdle_remaining',
      'carried_interest_distributed',
    ]
    let keys = []

    if (disbursement_type === 'normal') {
      keys = normal_distribution_keys.concat(shared_keys)
    } else {
      keys = interest_distribution_keys.concat(shared_keys)
    }

    return transfers.reduce((acc, transfer) => {
      if (transfer.is_active) {
        keys.forEach((key) => {
          const acc_value = acc[key] || initialMoney
          const transfer_value = transfer[key] || initialMoney
          acc[key] = sum([acc_value, transfer_value])
        })
      }
      return acc
    }, {})
  }

  return {
    transformTransfer,
    updateTransfer: updateTransfer,
    macrosUpdateTransfer,
    calculateAggregate,
  }
}
