<script setup lang="ts">
import { capitalize } from 'lodash'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCan } from '@/modules/auth/composables/use-can'
import { rails_url } from '@/modules/shared/utils/rails'
import { VTabList } from '@/modules/shared/components'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'

const { can } = useCan()
const { t } = useI18n()
const route = useRoute()
const authStore = useAuthStore()
const investorStore = useInvestorStore()
const workspaceStore = useWorkspaceStore()

const id = computed(() => route.params.entity_id || route.params.transacting_id)
const type = computed(() => route.params.entity_type || route.params.transacting_type)
const cid = computed(() => `${type.value}:${id.value}`)
const entityURL = computed(() => `${rails_url()}/funds/${id.value}`)

const isCurrent = (name: string) => route.name === name

const selected_commitment_ids = computed(() =>
  investorStore.state.selectedKeys.map((investor) => investorStore.state.profiles[investor].commitment_id),
)

onMounted(async () => {
  await workspaceStore.fetchCurrent()
})
</script>

<template>
  <div class="mb-12 px-4 sm:px-0">
    <VTabList
      :tabs="[
        {
          current: isCurrent('investing.entity-overview'),
          name: capitalize(t('shared.overview')),
          to: {
            name: 'investing.entity-overview',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
        },
        {
          current: isCurrent('investing.entity.portfolio'),
          name: capitalize(t('shared.portfolio')),
          to: {
            name: 'investing.entity.portfolio',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
        },
        {
          current: isCurrent('investing.investors'),
          name: capitalize(t('shared.investor', 0)),
          to: {
            name: 'investing.investors',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
        },
        {
          current: isCurrent('investing.calls'),
          name: capitalize(t('shared.call', 0)),
          to: {
            name: 'investing.calls',
            params: {
              transacting_id: id,
              transacting_type: type,
            },
          },
        },
        {
          current: isCurrent('investing.distributions'),
          name: capitalize(t('shared.distribution', 0)),
          to: {
            name: 'investing.distributions',
            params: {
              transacting_id: id,
              transacting_type: type,
            },
          },
        },
        {
          current: isCurrent('investing.trades'),
          name: 'Trades',
          to: {
            name: 'investing.trades',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
        },
        {
          current: isCurrent('investing.entity.capital-account'),
          name: capitalize(t('shared.capital account')),
          to: {
            name: 'investing.entity.capital-account',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
          visible: authStore.is_site_admin,
        },
        {
          current: isCurrent('investing.entity.documents'),
          name: t('shared.eSignature', 0),
          to: {
            name: 'investing.entity.documents',
            params: {
              entity_id: id,
              entity_type: type,
            },
          },
        },
        {
          current: false,
          name: capitalize(t('shared.file', 0)),
          to: `${entityURL}/files`,
        },
        {
          current: false,
          name: 'Notes',
          to: `${entityURL}/notes`,
          visible: can('siteadmin', cid),
        },
      ]"
    />
  </div>
</template>
